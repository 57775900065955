<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25 text-white">
    ©  {{ year }}
    <span class="ml-25 text-white" >Unilever</span>
    <span class="d-none d-sm-inline-block text-white">, All rights Reserved</span>
  </span>
  <span class="float-md-right d-none d-md-block text-white">
    Made by <a href="https://www.itx360.com/" target="_blank">
    <img src="https://www.itx360.com/wp-content/themes/itx/assets/img/footer-logo.png" alt="" class="img-fluid m-0">
    </a>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

import {CoreMenu} from '@core/types';

export const menu1: CoreMenu[] = [
    // Dashboard
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD.COLLAPSIBLE',
        type: 'item',
        // role: ['Admin'], //? To hide collapsible based on user role
        icon: 'home',
        url: 'dashboard'
    },
    // Masters
    {
        id: 'master',
        type: 'collapsible',
        title: 'Masters',
        icon: 'layers',
        children: [
            {
                id: 'authentication',
                title: 'Authentication',
                type: 'collapsible',
                icon: 'settings',
                children: [
                    {
                        id: 'role',
                        title: 'Role',
                        type: 'item',
                        icon: 'user-plus',
                        url: 'master/authentication/role/form'
                    },
                    {
                        id: 'user',
                        title: 'User',
                        type: 'item',
                        icon: 'user',
                        url: 'master/authentication/user/form'
                    }
                ]
            },
            {
                id: 'category',
                title: 'Category',
                type: 'item',
                icon: 'grid',
                url: 'master/category/form'
            },
            {
                id: 'material',
                title: 'Waste Label',
                type: 'item',
                icon: 'package',
                url: 'master/material/form'
            },
            {
                id: 'chemical-management',
                title: 'Chemical Management',
                type: 'item',
                icon: 'package',
                url: 'master/chemical-management/form'
            },
            {
                id: 'company',
                title: 'Company',
                type: 'item',
                icon: 'globe',
                url: 'master/company/form'
            },
            {
                id: 'bank',
                title: 'Bank',
                type: 'item',
                icon: 'credit-card',
                url: 'master/bank/form'
            },
            {
                id: 'agreement',
                title: 'Agreements',
                type: 'item',
                icon: 'file',
                url: 'master/agreement/form'
            }
        ]
    },
    //Transaction
    {
        id: 'transaction',
        type: 'collapsible',
        title: 'Transaction',
        icon: 'truck',
        children: [
            {
                id: 'bidding-option',
                title: 'Bidding Option',
                type: 'collapsible',
                icon: 'dollar-sign',
                children: [
                    {
                        id: 'bidding-master',
                        title: 'Bid Request',
                        type: 'item',
                        icon: 'folder-plus',
                        url: 'transaction/bidding-option/master/form'
                    },
                    {
                        id: 'pending-bids',
                        title: 'Todo List',
                        type: 'item',
                        icon: 'check-square',
                        url: 'transaction/bidding-option/pending-bids/form'
                    },
                ]
            },
            {
                id: 'rate-master',
                title: 'Rate Master',
                type: 'item',
                icon: 'activity',
                url: 'transaction/rate-master/form'
            },
            {
                id: 'waste-proposal',
                title: 'Disposal Request',
                type: 'collapsible',
                icon: 'airplay',
                children: [
                    {
                        id: 'master',
                        title: 'Request',
                        type: 'item',
                        icon: 'folder-plus',
                        url: 'transaction/waste-proposal/master/form'
                    },
                    {
                        id: 'pending-disposal',
                        title: 'Todo List',
                        type: 'item',
                        icon: 'check-square',
                        url: 'transaction/waste-proposal/pending-disposal/form'
                    },
                    {
                        id: 'process',
                        title: 'Completion',
                        type: 'item',
                        icon: 'calendar',
                        url: 'transaction/waste-proposal/process/form'
                    }
                ]
            },
        ]
    },
    //Report
    // {
    //     id: 'report',
    //     type: 'collapsible',
    //     title: 'Report',
    //     icon: 'bookmark',
    //     children: []
    // }
];

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    // Public
    public search = '';
    public apiData = [];
    public onApiDataChange: BehaviorSubject<any>;
    public onIsBookmarkOpenChange: BehaviorSubject<any>;
    public searchData = [
        {
            groupTitle: 'Master',
            searchLimit: 4,
            data: [
                {
                    id: 0,
                    target: 'dashboard',
                    title: 'Dashboard',
                    icon: 'home',
                    link: '/'
                },
                {
                    id: 1,
                    target: 'role',
                    title: 'Role',
                    icon: 'user-plus',
                    link: 'master/authentication/role/form'
                },
                {
                    id: 2,
                    target: 'user',
                    title: 'User',
                    icon: 'user',
                    link: 'master/authentication/user/form'
                },
                {
                    id: 3,
                    target: 'category',
                    title: 'Category',
                    icon: 'grid',
                    link: 'master/category/form'
                },
                {
                    id: 4,
                    target: 'agreement',
                    title: 'Agreements',
                    icon: 'file',
                    link: 'master/agreement/form'
                },
                {
                    id: 5,
                    target: 'material',
                    title: 'Material',
                    icon: 'package',
                    link: 'master/material/form'
                },
                {
                    id: 6,
                    target: 'company',
                    title: 'Company',
                    icon: 'globe',
                    link: 'master/company/form'
                },
                {
                    id: 7,
                    target: 'bank',
                    title: 'Bank',
                    icon: 'credit-card',
                    link: 'master/bank/form'
                }
            ]
        },
        {
            groupTitle: 'Transaction',
            searchLimit: 4,
            data: [
                {
                    id: 1,
                    target: 'bidding-master',
                    title: 'Bid Request',
                    icon: 'folder-plus',
                    link: 'transaction/bidding-option/master/form'
                },
                {
                    id: 2,
                    target: 'pending-bids',
                    title: 'Todo List',
                    icon: 'check-square',
                    link: 'transaction/bidding-option/pending-bids/form'
                },
                {
                    id: 3,
                    target: 'rate-master',
                    title: 'Rate Master',
                    icon: 'activity',
                    link: 'transaction/rate-master/form'
                },
                {
                    id: 4,
                    target: 'waste-proposal',
                    title: 'Waste Proposal',
                    icon: 'folder-plus',
                    link: 'transaction/waste-proposal/master/form'
                },
                {
                    id: 5,
                    target: 'waste-process',
                    title: 'Waste Process',
                    icon: 'calendar',
                    link: 'transaction/waste-proposal/process/form'
                }
            ]
        }
    ];

    constructor(private _httpClient: HttpClient) {
        this.onApiDataChange = new BehaviorSubject('');
        this.onIsBookmarkOpenChange = new BehaviorSubject(false);
        this.getSearchData();
    }

    getSearchData() {
        this.onApiDataChange.next(this.searchData);
    }

}



import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import {JwtHelperService} from "./jwt-helper.service";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
      private _router: Router,
      private _authenticationService: AuthenticationService,
      private jwtHelper: JwtHelperService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this._authenticationService.currentUserValue;
    const token = this._authenticationService.currentTokenValue;

    if (currentUser && token && !this.jwtHelper.isTokenExpired(token)) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.roleName) === -1) {
        // role not authorized so redirect to not-authorized page
        this._router.navigate(['/miscellaneous/not-authorized']);
        return false;
      }
      // authorized so return true
      return true;
    }

    // token expired or not logged in so redirect to login page with the return URL
    this._router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    // Attempt to refresh the token
                    return this._authenticationService.refreshToken().pipe(
                        switchMap(() => {
                            // Retry the original request with the new token
                            const token = this._authenticationService.currentTokenValue;
                            request = request.clone({
                                setHeaders: { Authorization: `Bearer ${token}` }
                            });
                            return next.handle(request);
                        }),
                        catchError(refreshError => {
                            // Refresh failed, redirect to login
                            this._authenticationService.logout();
                            this._router.navigate(['/account/login']);
                            return throwError(refreshError);
                        })
                    );
                }
                if (err.status === 403) {
                    // Forbidden, navigate to not-authorized
                    this._router.navigate(['/miscellaneous/not-authorized']);
                }
                const error = err.error.message || err.statusText;
                return throwError(error);
            })
        );
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {HttpService} from "../helpers/http-service";
import {User} from "../../main/master/authentication/user/user";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentTokenSubject: BehaviorSubject<string>;

  public currentUser: Observable<User>;
  public currentToken: Observable<string>;

  constructor(private http: HttpClient, private toastr: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentTokenSubject = new BehaviorSubject<string>(localStorage.getItem('currentToken') || '');

    this.currentUser = this.currentUserSubject.asObservable();
    this.currentToken = this.currentTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  login(username: string, password: string): Observable<User> {
    return this.http.post<any>(HttpService.SERVICE_PATH + '/auth/authenticate', { username, password }).pipe(
        map(response => {
          if (response.user && response.access_token) {
            localStorage.setItem('currentUser', JSON.stringify(response.user));
            this.currentUserSubject.next(response.user);
            this.storeTokens(response.access_token, response.refresh_token);
            setTimeout(() => {
              this.toastr.success(
                  'Congratulations! You\'re now logged in and ready to explore. ',
                  `👋 Welcome, ${response.user.firstName}!`,
                  { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);
            return response.user;
          }else {
            console.log('API response received but user or token missing');
          }
          return null;
        }),
        catchError(error => {
          return throwError(error);
        })
    );
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    localStorage.removeItem('refreshToken');
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.http.post(HttpService.SERVICE_PATH + '/auth/refresh-token', { refreshToken })
        .pipe(map((response: any) => {
          if (response.access_token) {
            this.storeTokens(response.access_token, refreshToken);
          }
          return response;
        }));
  }

  private storeTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem('currentToken', JSON.stringify(accessToken));
    localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
    this.currentTokenSubject.next(accessToken);
  }
}

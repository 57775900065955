import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {HttpService} from "../../auth/helpers/http-service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(private _http: HttpClient) {
    }

    upload(formData: FormData): Observable<any> {
        const url = HttpService.SERVICE_PATH + '/document/upload';
        return this._http.post(url, formData)
            .pipe(map(response => {
                return response;
            }));
    }

    postFileS3(formData: FormData): Observable<any>  {
        const url = HttpService.SERVICE_PATH + '/file/upload';
        return this._http.post(url, formData)
            .pipe(map(response => {
                return response;
            }));
    }

    postImageS3(formData: FormData): Observable<any>  {
        const url = HttpService.SERVICE_PATH + '/file/upload/image';
        return this._http.post(url, formData)
            .pipe(map(response => {
                return response;
            }));
    }

    getFileS3(docId: number): Observable<any>  {
        const url = HttpService.SERVICE_PATH + '/file/download';
        let params = new HttpParams();
        params = params.append("docId", docId);
        return this._http.get(url, {params: params})
            .pipe(map(response => {
                return response;
            }));
    }
}

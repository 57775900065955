<li class="nav-item nav-search">
    <!-- Search icon -->
    <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
    ></a>

    <!-- Search -->
    <div class="search-input" [class.open]="openSearchRef === true">
        <!-- Search Input -->
        <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
        <input
                class="form-control input"
                type="text"
                placeholder="Search..."
                tabindex="-1"
                data-search="search"
                (keyup)="searchUpdate($event)"
                [(ngModel)]="searchText"
                #openSearch
        />
        <button class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

        <!-- Search List -->
        <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
            <!-- Pages -->
            <li class="d-flex align-items-center">
                <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">master</h6></a>
            </li>
            <ng-container *ngIf="(masters | filter: searchText:'title').length; else noResultsPages">
                <li
                        class="auto-suggestion"
                        *ngFor="let master of masters | filter: searchText:'title' | slice: 0:4; let i = index"
                        [ngClass]="{ current_item: i === activeIndex }"
                        [class.current_item]="master.hover"
                        (mouseover)="master.hover = true"
                        (mouseout)="master.hover = false"
                >
                    <a
                            class="d-flex align-items-center justify-content-between w-100"
                            (click)="toggleSearch()"
                            [routerLink]="master.link"
                    >
                        <div class="d-flex justify-content-start align-items-center">
                            <i [data-feather]="master.icon" class="mr-75"></i><span>{{ master.title }}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-container>

            <!-- No results found -->
            <ng-template #noResultsPages>
                <li class="auto-suggestion justify-content-between">
                    <a class="d-flex align-items-center justify-content-between w-100 py-50">
                        <div class="d-flex justify-content-start">
                            <i data-feather="alert-circle" class="mr-25"></i>
                            <span>No results found.</span>
                        </div>
                    </a
                    >
                </li>
            </ng-template>
            <!--/ Pages -->

            <!-- Files -->
            <li class="d-flex align-items-center">
                <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">transaction</h6></a>
            </li>
            <ng-container *ngIf="(transactions | filter: searchText:'title').length; else noResultsPages">
                <li
                        class="auto-suggestion"
                        *ngFor="let transaction of transactions | filter: searchText:'title' | slice: 0:4; let i = index"
                        [ngClass]="{ current_item: i === activeIndex }"
                        [class.current_item]="transaction.hover"
                        (mouseover)="transaction.hover = true"
                        (mouseout)="transaction.hover = false"
                >
                    <a
                            class="d-flex align-items-center justify-content-between w-100"
                            (click)="toggleSearch()"
                            [routerLink]="transaction.link"
                    >
                        <div class="d-flex justify-content-start align-items-center">
                            <i [data-feather]="transaction.icon" class="mr-75"></i><span>{{ transaction.title }}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-container>
            <!-- No results found -->
            <ng-template #noResultsFiles>
                <li class="auto-suggestion justify-content-between">
                    <a class="d-flex align-items-center justify-content-between w-100 py-50">
                        <div class="d-flex justify-content-start">
                            <i data-feather="alert-circle" class="mr-25"></i>
                            <span>No results found.</span>
                        </div>
                    </a
                    >
                </li>
            </ng-template>
            <!--/ Files -->
        </ul>
        <!--/ Search List -->
    </div>
</li>
